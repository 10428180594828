import { v4 as uuidv4 } from 'uuid';

export const formJson = {
  "showProgressBar": "none",
  "progressBarType": "pages",
  "progressBarShowPageNumbers": true,
  "progressBarShowPageTitles": false,
  "pageNextText": "Розпочати",
  "pagePrevText": "Попередні запитання",
  "completeText": "Відправити відповіді",
  "completedHtml": `<div class=completed-page>
    <span class=checkmark></span>
    <h4>Дякуємо, ми отримали ваші відповіді!</h4>
  <div>`,
  "fitToContainer": "true",
  "pages": [{
    "name": "page0",
    "cssClasses": "page-0",
    "elements": [
      {
        "type": "html",
        "name": "info",
        "html": `<h4>Про проєкт</h4>
          <p>Компанія Feodal разом з міжнародними партнерами проводить дослідження, яке має на меті вияснити, 
            як допомогти фермерам, які не можуть використовувати свою землю через 
            наслідки повномасштабного вторгнення і які мають заміновані та забрудненні 
            вибухонебезпечними предметами поля.   
          </p>
          <p>Дане опитування не є рекламою. Це дослідницький проєкт, 
            що фінансується міжнародними донорами,
            для того щоб якомога краще зрозуміти потреби фермерів у допомозі.
          </p>
        `
      }
    ]
  },
  {
    "name": "page1",
    "title": "Особиста інформація",
    "description": "",
    "elements": [
      {
        "type": "text",
        "name": "uuid",
        "defaultValue": uuidv4(),
        "visible": false
      },
      {
        "type": "text",
        "name": "Прізвище та ім'я",
        "isRequired": true,
        "requiredErrorText": "Це поле обов'язкове для заповнення",
        "titleLocation": "hidden",
        "placeholder": "Ваше прізвище та ім'я",
        "cssClasses": ["input-with-people-icon"],
      },
      {
        "type": "text",
        "name": "Номер телефону",
        "isRequired": true,
        "requiredErrorText": "Це поле обов'язкове для заповнення",
        "titleLocation": "hidden",
        "inputMask": "phone",
        "inputFormat": "+99 (999) 999-99-99",
        "placeholder": "+38 (___) ___-__-__"
      },
      {
        "type": "text",
        "name": "ЄДРПОУ",
        "titleLocation": "hidden",
        "placeholder": "Введіть код ЄДРПОУ"
      },
      {
        "type": "text",
        "name": "Область",
        "titleLocation": "hidden",
        "placeholder": "Область"
      },
      {
        "type": "radiogroup",
        "name": "Член кооперативу",
        "title": `Член кооперативу`,
        "hideNumber": true,
        "showNoneItem": false,
        "showOtherItem": false,
        "choices": [
          "Так",
          "Ні",
        ],
      }]
  }, {
    "name": "page2",
    "title": "Стан бізнесу в умовах війни",
    "elements": [{
      "type": "radiogroup",
      "name": "Яким видом аграрного бізнесу ви займались до війни?",
      "title": "Яким видом аграрного бізнесу ви займались до війни?",
      "isRequired": false,
      "showNoneItem": false,
      "otherText": "Інше",
      "showOtherItem": true,
      "colCount": 1,
      "choices": [
        "Вирощування польових культур",
        "Тваринництво",
        "Садівництво",
        "Ягідництво",
      ],
      "separateSpecialChoices": true,
    },
    {
      "type": "radiogroup",
      "name": "Яка ваша основна діяльність або який основний бізнес?",
      "title": "Яка ваша основна діяльність або який основний бізнес?",
      "isRequired": false,
      "showNoneItem": false,
      "showOtherItem": false,
      "colCount": 1,
      "choices": [
        "Фермерство",
        "Окрім фермерства маю інші джерела доходу",
      ],
      "separateSpecialChoices": true,
    },
    {
      "type": "checkbox",
      "name": "Які втрати ви зазнали з початку повномасштабного вторгнення? (можете обрати декілька варіантів)",
      "title": `Які втрати ви зазнали з початку повномасштабного вторгнення? (можете обрати декілька варіантів)`,
      "isRequired": false,
      "showNoneItem": false,
      "showOtherItem": true,
      "otherText": "Інше",
      "colCount": 1,
      "separateSpecialChoices": false,
      "choices": [
        "Будівлі (склади, автозаправні станції, зерносховища, адміністративні будівлі тощо)",
        "Посадковий матеріал",
        "Зерно",
        "Поголів’я тварин",
        "Сільськогосподарська техніка",
        "Можливість працювати на полях",
        "Робочих та працівників",
        "Нічого з переліченого",
      ],
    },
    {
      "type": "comment",
      "name": "Вкажіть конкретний об'єкт втрати",
      "title": "Вкажіть конкретний об'єкт втрати",
      "visibleIf": "{Які втрати ви зазнали з початку повномасштабного вторгнення? (можете обрати декілька варіантів)} contains 'Будівлі (склади, автозаправні станції, зерносховища, адміністративні будівлі тощо)'",
    },
    {
      "type": "text",
      "name": "За вашою орієнтовною оцінкою, скільки ви втратили через повномасштабне вторгнення?",
      "title": "За вашою орієнтовною оцінкою, скільки ви втратили через повномасштабне вторгнення?",
      "placeHolder": "Введіть суму в гривнях",
    },
    ]
  },
  {
    "name": "page3",
    "title": "Гуманітарне розмінування",
    "elements": [
      {
        "type": "radiogroup",
        "name": "Як ви думаєте, яка частина ваших земель містить міни або інші вибухонебезпечні предмети?",
        "title": "Як ви думаєте, яка частина ваших земель містить міни або інші вибухонебезпечні предмети?",
        "isRequired": false,
        "showNoneItem": false,
        "showOtherItem": false,
        "colCount": 1,
        "choices": [
          "до 25%",
          "від 25 до 50%",
          "від 50 до 75%",
          "від 75 до 100%",
          "Не маю ймовірно замінованих ділянок.",
          "Не знаю"
        ],
        "separateSpecialChoices": true,
      },
      {
        "type": "radiogroup",
        "name": "Наскільки ймовірне мінування ваших полів впливає зараз на життя вашого бізнесу?",
        "title": "Наскільки ймовірне мінування ваших полів впливає зараз на життя вашого бізнесу?",
        "isRequired": false,
        "showNoneItem": false,
        "showOtherItem": false,
        "colCount": 1,
        "choices": [
          "Не впливає",
          "Бізнес дещо уражений, але ми знайшли спосіб працювати",
          "Повністю паралізувало нашу роботу",
        ],
        "separateSpecialChoices": true,
      },
      {
        "type": "radiogroup",
        "name": "Чи працюєте ви в полях, незважаючи на можливий ризик мінування?",
        "title": "Чи працюєте ви в полях, незважаючи на можливий ризик мінування?",
        "isRequired": false,
        "showNoneItem": false,
        "showOtherItem": false,
        "colCount": 1,
        "choices": [
          "Так",
          "Ні",
        ],
        "separateSpecialChoices": true,
      },
      {
        "type": "comment",
        "name": "Чому ви працюєте в полях?",
        "title": "Чому ви працюєте в полях?",
        "visibleIf": "{Чи працюєте ви в полях, незважаючи на можливий ризик мінування?} = 'Так'",
      },
      {
        "type": "radiogroup",
        "name": "Чи хтось обстежував ваші поля на наявність мін та інших вибухонебезпечних предметів?",
        "title": "Чи хтось обстежував ваші поля на наявність мін та інших вибухонебезпечних предметів?",
        "isRequired": false,
        "showNoneItem": false,
        "showOtherItem": false,
        "colCount": 1,
        "choices": [
          "Так",
          "Ні",
        ],
        "separateSpecialChoices": true,
      },
      {
        "type": "radiogroup",
        "name": "Хто обстежував ваші поля?",
        "title": "Хто обстежував ваші поля?",
        "isRequired": false,
        "showNoneItem": false,
        "showOtherItem": true,
        "otherText": "Інше",
        "visibleIf": "{Чи хтось обстежував ваші поля на наявність мін та інших вибухонебезпечних предметів?} = 'Так'",
        "colCount": 1,
        "choices": [
          "Міжнародна або українська благодійна організація з протимінної діяльності (наприклад  HALO Trust, FSD, Demining Solution  та інші)",
          "Державні організації",
          "Мені допомогли знайомі",
          "Я не впевнений, що знаю, хто обстежував"
        ],
        "separateSpecialChoices": true,
      },
      {
        "type": "radiogroup",
        "name": "Які результати обстеження?",
        "title": "Які результати обстеження?",
        "isRequired": false,
        "showNoneItem": false,
        "showOtherItem": false,
        "visibleIf": "{Чи хтось обстежував ваші поля на наявність мін та інших вибухонебезпечних предметів?} = 'Так'",
        "colCount": 1,
        "choices": [
          "Мої поля ймовірно безпечні",
          "Частина полів заміновані, частина безпечна",
          "Всі поля заміновані, чекаю розмінування",
          "Я очікую результати обстеження",
          "Я не отримав результатів"
        ],
        "separateSpecialChoices": true,
      },
      {
        "type": "radiogroup",
        "name": "Якби сертифікований оператор протимінної діяльності завершив нетехнічне обстеження та сказав вам, що ваша земля безпечна, ви б…",
        "title": "Якби сертифікований оператор протимінної діяльності завершив нетехнічне обстеження та сказав вам, що ваша земля безпечна, ви б…",
        "isRequired": false,
        "showNoneItem": false,
        "showOtherItem": false,
        "colCount": 1,
        "choices": [
          "Негайно повернувся до роботи на свої поля",
          "Радився би з друзями та знайомими, чи заслуговує на довіру організація, що проводила обстеження",
          "Порадились би з іншими, чи можна працювати на полі після обстеження, і чи хтось вже це робив",
          "Заперечували б ефективність обстеження і вимагали, щоб обстежили детальніше з використанням машин, які пройдуться по кожному клаптику землі",
        ],
        "separateSpecialChoices": true,
      },
      {
        "type": "comment",
        "name": "Що вас найбільше турбує в питанні замінуванні ваших полів?",
        "title": "Що вас найбільше турбує в питанні замінуванні ваших полів? Наприклад, що про вас забудуть, або що ваші проблеми непочуті і ніхто не допомагає, або ваш варіант?",
      },
    ]
  },
  {
    "name": "page4",
    "title": "Урядова допомога та підтримка",
    "elements": [
      {
        "type": "radiogroup",
        "name": "Чи отримували ви якусь допомогу чи підтримку від українського Уряду для відновлення вашого підприємства/господарства/бізнесу?",
        "title": "Чи отримували ви якусь допомогу чи підтримку від українського Уряду для відновлення вашого підприємства/господарства/бізнесу?",
        "isRequired": false,
        "showNoneItem": false,
        "showOtherItem": false,
        "colCount": 1,
        "choices": [
          "Так",
          "Ні",
          "Звернувся, чекаю допомоги",
        ],
        "separateSpecialChoices": true,
      },
      {
        "type": "comment",
        "name": "Яка саме допомога?",
        "title": "Яка саме допомога?",
        "visibleIf": "{Чи отримували ви якусь допомогу чи підтримку від українського уряду для відновлення вашого підприємства/господарства/бізнесу?} = 'Так'",
      },
      {
        "type": "comment",
        "name": "Чому не отримали допомогу?",
        "title": "Чому не отримали допомогу?",
        "visibleIf": "{Чи отримували ви якусь допомогу чи підтримку від українського уряду для відновлення вашого підприємства/господарства/бізнесу?} = 'Ні'",
      },
      {
        "type": "radiogroup",
        "name": "Ви бачили або чули про будь-які спеціальні програми, які допомагають фермерам що постраждали внаслідок повномасштабного вторгнення?",
        "title": "Ви бачили або чули про будь-які спеціальні програми, які допомагають фермерам що постраждали внаслідок повномасштабного вторгнення?",
        "isRequired": false,
        "showNoneItem": false,
        "showOtherItem": false,
        "colCount": 1,
        "choices": [
          "Так",
          "Ні",
          "Я не впевнений",
        ],
        "separateSpecialChoices": true,
      },
      {
        "type": "comment",
        "name": "Що це за програма?",
        "title": "Що це за програма?",
        "visibleIf": "{Ви бачили або чули про будь-які спеціальні програми, які допомагають фермерам що постраждали внаслідок повномасштабного вторгнення?} = 'Так'",
      },
      {
        "type": "radiogroup",
        "name": "Ви чули про нову програму Уряду щодо розмінування сільськогосподарських земель?",
        "title": "Ви чули про нову програму Уряду щодо розмінування сільськогосподарських земель?",
        "isRequired": false,
        "showNoneItem": false,
        "showOtherItem": false,
        "colCount": 1,
        "choices": [
          "Не чув",
          "Чув, але не вникаю в це",
          "Знаю і моніторю ці програми",
        ],
        "separateSpecialChoices": true,
      },
      {
        "type": "comment",
        "name": "Що ви думаєте про урядову програму гуманітарного розмінування?",
        "title": "Що ви думаєте про урядову програму гуманітарного розмінування?",
      },
    ]
  },
  {
    "name": "page5",
    "title": "Здоров’я ґрунту та інші важливі питання",
    "elements": [
      {
        "type": "radiogroup",
        "name": "Чи турбує вас здоров’я ґрунту?",
        "title": "Чи турбує вас здоров’я ґрунту? Наявність хімічних забруднюючих речовин у землі і воді в результаті забруднення території вибухонебезпечними предметами?",
        "isRequired": false,
        "showNoneItem": false,
        "showOtherItem": false,
        "colCount": 1,
        "choices": [
          "Так",
          "Не турбує",
          "Не думав про це",
        ],
        "separateSpecialChoices": true,
      },
      {
        "type": "comment",
        "name": "Як ви бачите вирішення питання хімічного забруднення ґрунту в майбутньому?",
        "title": "Як ви бачите вирішення питання хімічного забруднення ґрунту в майбутньому?",
        "visibleIf": "{Чи турбує вас здоров’я ґрунту?} = 'Так'",
      },
      {
        "type": "radiogroup",
        "title": "Чи виникли у вас труднощі з продажем сільськогосподарської продукції з 2023 року?",
        "name": "Чи виникли у вас труднощі з продажем сільськогосподарської продукції з 2023 року?",
        "isRequired": false,
        "showNoneItem": false,
        "showOtherItem": false,
        "colCount": 1,
        "choices": [
          "Так",
          "Ні",
          "Веду підсобне господарство"
        ],
      },
      {
        "type": "comment",
        "name": "Які саме труднощі виникли?",
        "title": "Які саме труднощі виникли?",
        "visibleIf": "{Чи виникли у вас труднощі з продажем сільськогосподарської продукції з 2023 року?} = 'Так'",
      },
      {
        "type": "comment",
        "name": "Що потрібно знати державі і гравцям на ринку гуманітарного розмінування про вашу роботу?",
        "title": "Що потрібно знати державі і гравцям на ринку гуманітарного розмінування про вашу роботу, про вас і вашу проблему з якою ви зіштовхнулись внаслідок замінування полів?"
      },
      {
        "type": "comment",
        "name": "Чи хотіли б ви поділитися своєю історією з міжнародною аудиторією?",
        "title": `Чи хотіли б ви поділитися своєю історією з міжнародною аудиторією?
          Якщо так, розкажіть коротко про вашу історію і як вона може допомогти іншим фермерам?
        `
      },
    ],
  }
  ],
};