import 'survey-core/defaultV2.min.css';
import { useEffect, useState } from 'react';
import { Model } from 'survey-core';
import { formJson } from './formJson';
import { Survey } from 'survey-react-ui';
import { themeJson } from '../theme';
import { inputmask } from "surveyjs-widgets";
import * as SurveyCore from "survey-core";

inputmask(SurveyCore);

export const Form = () => {
  const [survey, setSurvey] = useState(null);
  const customCss = {
    "question": {
      "mainRoot": "question-root-custom",
    }
  };
  const originalCss = {
    "question": {
      "mainRoot": "question-root"
    }
  }

  useEffect(() => {
    const initializeSurvey = () => {
      const surveyModel = new Model(formJson);
      surveyModel.onValueChanged.add(handleValueChanged);
      surveyModel.onComplete.add(handleValueChanged)

      surveyModel.applyTheme(themeJson);
      setSurvey(surveyModel);
      changeBtnTitle(surveyModel)
    };

    initializeSurvey();

    return () => {
      if (survey) {
        survey.onValueChanged.remove(handleValueChanged);
      }
    };
  }, []);

  useEffect(() => {
    if (!survey) return;
    addOrRemoveClassToNextBtn('add');
    document.querySelector('.sd-body__navigation').style.paddingTop = '8px';
    document.querySelector('.sd-body').append(aboutBlock())
  }, [survey])

  const addOrRemoveClassToNextBtn = (action) => {
    const element = document.querySelector('#sv-nav-next');

    if (!element) return;
    const className = 'one-btn';
    if (action === 'add') {
      element.classList.add(className);
    } else {
      element.classList.remove(className);
    }
  };

  const aboutBlock = () => {
    const about = document.createElement('div');
    about.classList.add('about');
    about.innerHTML = `
      <p>Наша мета — зрозуміти, які реальні проблеми у агропідприємств, що постраждали від війни, 
        та знайти шляхи для якіснішої підтримки фермерства спільно з урядами, 
        міжнародними партнерами та іншими фермерами.
      </p>
      <p>Це опитування, сподіваємося, дасть нам і світу краще уявлення про дійсні та 
        поточні проблеми фермерів, що знаходяться на деокупованих територіях.
      </p>
      <p>Ваші дані є строго конфіденційними. Це означає, що ніяка інформація,
        яка може вас ідентифікувати, наприклад, ім’я, електронна адреса, номер телефону, 
        місцезнаходження, тип бізнесу, ніколи не буде опублікована або передана третім особам.
      </p>
    `;
    return about;
  }

  const changeBtnTitle = (survey) => {
    survey.onCurrentPageChanged.add(function (sender, options) {
      const currentPage = options.newCurrentPage;

      if (currentPage && currentPage.visibleIndex === 0) {
        sender.showProgressBar = 'none';
        sender.pageNextText = "Розпочати";
        addOrRemoveClassToNextBtn('add');
        survey.css = originalCss;
        if (document.querySelector('.about')) return;
        document.querySelector('.sd-body').append(aboutBlock())
      } else if (currentPage && currentPage.visibleIndex === 1) {
        sender.pageNextText = "Перейти до запитань";
        document.querySelector('#sv-nav-prev').style.display = 'none';
        addOrRemoveClassToNextBtn('add');
        survey.css = originalCss;
        document.querySelector('.about')?.remove()
        document.querySelector('.sd-body__navigation').style.paddingTop = '24px';
      } else if (currentPage && currentPage.visibleIndex === 2) {
        sender.showProgressBar = 'aboveHeader';
        sender.pagePrevText = "Назад";
        sender.pageNextText = "Наступні запитання";
        document.querySelector('#sv-nav-prev').style.display = 'block';
        survey.css = customCss;
        addOrRemoveClassToNextBtn('remove')
        document.querySelector('.about')?.remove()
        document.querySelector('.sd-body__navigation').style.paddingTop = '24px';
      } else {
        sender.showProgressBar = 'aboveHeader';
        sender.pagePrevText = "Попередні запитання";
        sender.pageNextText = "Наступні запитання";
        document.querySelector('#sv-nav-prev').style.display = 'block';
        survey.css = customCss;
        addOrRemoveClassToNextBtn('remove')
        document.querySelector('.about')?.remove()
        document.querySelector('.sd-body__navigation').style.paddingTop = '24px';
      }
    });
  };


  const handleValueChanged = (sender, options) => {
    const surveyData = sender.data;
    if (surveyData.uuid) {
      localStorage.setItem('uuid', surveyData.uuid);
    } else {
      surveyData.uuid = localStorage.getItem('uuid');
    }

    // Send surveyData to your server, API, or perform any other action
    fetch('https://ask.feodal.online/polls/', {
      method: 'POST',
      body: JSON.stringify(surveyData),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((response) => response.json())
      .then((json) => console.log('Server response:', json)
      )
      .catch((error) => console.error('Error:', error));
  };

  return (survey && <Survey model={survey} className="content" />);
};
