import './App.css';
import { Form } from './Form/Form.js';
import { Sidebar } from './Sidebar/Sidebar.js';

function App() {
  return (
    <div className='page'>
      <Sidebar />
      <Form />
    </div>
  );
}

export default App;
