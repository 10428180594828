import { ReactComponent as FeodalLogo } from '../icons/feodal-logo.svg';

export const Sidebar = () => {
    return (<div className="sidebar">
        <div className="logo">
            <span>Голос фермера</span>
            <FeodalLogo />
        </div>
        <div className="header">
            <span className="sub-logo">
                Допомагаємо фермерам
                вирішувати їхні проблеми
            </span>
        </div>
        <div className="products">
            <span>Наші продукти:</span>
            <ul>
                <li><a href="https://feodal.online/#feodal.hromada">Feodal.Hromada</a></li>
                <li><a href="https://feodal.online/#feodal.land">Feodal.Land</a></li>
                <li><a href="https://feodal.online/#feodal.online">Feodal.Online</a></li>
                <li><a href="https://feodal.online/#feodal.project">Feodal.ПРОЄКТ</a></li>
                <li><a href="https://feodal.online/fms/">Feodal.FMS</a></li>
            </ul>
        </div>
        <div className="info">
            <span>Інфо | Контакти</span>
            <a href="#">Положення та Умови</a>
            <a href="#">Політика Конфідеційності</a>
            <a href="tel:0800308009">0 800 30 80 09</a>
        </div>

    </div>)
}